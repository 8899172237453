@tailwind base;
@tailwind components;
@tailwind utilities;

.content ul {
  @apply my-6;
}

@page {
  size: 11in 10in;
  margin: 0 !important;
}

#ad-preview  .snap-mandatory {
  overflow: hidden !important;
}

@media print {
  *{ color-adjust: exact;  -webkit-print-color-adjust: exact; print-color-adjust: exact; }

  canvas {
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    height: auto!important;
    width: auto!important;
  }

  .page-break {
    page-break-before: always
  }

  .footer {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    color: white;
  }
}